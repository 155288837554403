import { Typography, Divider, Stack, Button } from "@mui/material";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import React from "react";
import "./EyeCatcherStyles.css";

export default function EyeCatcherApp({app, setFormStart}) {
    const fancyButton = {
        backgroundColor: "#ffffff33",
        borderRadius: "20px",
        padding: "6px",
        width: "60%",
        color: "white",
        margin: "5px",
        fontFamily: "'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell','Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif",
        boxShadow: "2.5px 2.5px 10px rgba(20,20,20,0.5)"
    };

    return (
        <div style={{
            backgroundColor: "#8b878799",
            borderRadius: "5px",
            padding: "10px",
            height: "55vh",
            width: "15vw",
            boxShadow: "5px 5px 10px rgba(20,20,20,1)",
            overflow: "auto"
        }}>
            {
                app.icon_url !== undefined || app.icon_url !== null ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img 
                            src={app.icon_url} 
                            width={62} 
                            height={62}
                            alt="App Icon" 
                            className="app_icon" 
                        />
                    </div>
                ) : (
                    <></>
                )
            }
            <br/><Divider style={{ borderColor: "white" }} variant="middle" />
            <Typography variant="h6" style={{ textAlign: "center" }}>{app?.name}</Typography>
            <Divider style={{ borderColor: "white" }} variant="middle" /><br/>
            <Typography style={{ textAlign: "center", fontSize: "10pt" }}>{app?.overview}</Typography>
            <br/><Divider style={{ borderColor: "white" }} variant="middle" /><br/>

            <Stack spacing={0.25}>
                {
                app && Array.isArray(app.features) ?    app.features.map((feature) => (
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} key={feature}>
                            <DoneOutlineIcon style={{ color: "white", marginRight: "10px" }} />
                            <Typography>{feature}</Typography>
                        </div>
                    )) : null
                }
            </Stack>

            <br/><Divider style={{ borderColor: "white" }} variant="middle" /><br/>
            <Typography variant="h5" style={{ textAlign: "center" }}>
                {
                    app.banded_cost ? (
                        <>Charged By the {app.band_tag}</>
                    ) : (
                        <>£{app?.price} per {app?.scale_tag}</>
                    )
                }
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button 
                    sx={fancyButton}
                    onClick={() => {setFormStart(true)}}
                >
                    Signup
                </Button>
            </div>

        </div>
    )
}