const apiBase =
  process.env.NODE_ENV === "production"
    ? "https://" + window.location.hostname
    : "http://localhost:8080";

module.exports = {
  //This app url
  appURL: apiBase,
  supportEmail: "<suport_email>",
  tenantMiddlewareAddressBase: "https://tenant-middleware",
  appsServiceBase: "https://app-service"
};
