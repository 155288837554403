import React, { useEffect, useState } from "react";

import { Paper, Grid, Button, InputLabel, InputAdornment, Input, FormControl, NativeSelect } from "@mui/material";
import AppInfo from "./appInfo";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import "./appSelectorStyle.css";

export default function AppSelector(props) {
    const [ added, setAdded ] = useState(false);
    const [ users, setUsers ] = useState(1);
    const [ band, setBand ] = useState(1);
    const [ price, setPrice ] = useState("0.00");

    const addApp = () => {
        setAdded(true);
        if( props.app.banded_cost ) {
            handleBandChange( props.app.bands[0] );
        }
        else {
            handleUserChange( 1 );
        }
    }

    const removeApp = () => {
        setAdded(false);

        props.removeApp(props.app.id);
    }

    const handleUserChange = (value) => {
        if( value < 1 ) { setAdded(false); setUsers(1); removeApp(); return; }
        setUsers(value);
        setPrice( `Sub-Total: £${(value * props.app.price).toFixed(2)}` );

        props.updateApp(props.app.id, {
            price: (value * props.app.price).toFixed(2),
            banded_cost: false,
            users: value,
            name: props.app.name
        });
    }

    const handleBandChange = (value) => {
        setBand(value);

        const index = props.app.bands.findIndex((i) => {
            return i == value;
        });

        if( index === -1 || props.app.band_prices[index] === undefined ) {
            setPrice( "Contact Us For Details" );
        }
        else {
            setPrice( `Sub-Total: £${props.app.band_prices[index].toFixed(2)}` );
        }

        props.updateApp(props.app.id, { 
            price: props.app.band_prices[index].toFixed(2),
            banded_cost: true,
            band_index: index,
            band: value,
            band_tag: props.app.band_tag,
            name: props.app.name
        });
    }

    useEffect(() => {
        if( props.app.core ) {
            setAdded(true);
        }
    })

    useEffect(() => {
        var data = props.appSelectData;
        if( data === undefined ) return;

        if( data.branded_cost ) setBand(data.band); else setUsers(data.users);
        setPrice(data.price);
        setAdded(true);
            
    }, [props.appSelectData])

    return (
        <Paper className="appBody">
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={4}>
                    <h4>{props.app.name}</h4>
                </Grid>
                <Grid item xs={4}>
                    <AppInfo 
                        app={props.app}
                        addApp={addApp}
                        removeApp={removeApp}
                        added={added}
                    />
                </Grid>
                <Grid item xs={4}>
                    {
                        props.app.icon_url !== undefined || props.app.icon_url !== null ?(
                            <img src={props.app.icon_url} width={64} height={64} className="app_icon" />
                        ) : (
                            <></>
                        )
                    }
                </Grid>
                
            </Grid>
            <p>{props.app.overview}</p>
            <Grid container spacing={3} className="feature-container">
            {
                props.app.features != null && Array.isArray(props.app.features) && props.app.features.map((feature, key) => {
                    return (
                        <Grid item xs={12} key={key}  className="feature-block">
                        <Paper className="feature-content">
                        <DoneIcon className="feature-icon"/> 
                        <p>{feature}</p>
                        </Paper>
                        </Grid>
                    )
                })
            }
            </Grid>
            {
                props.app.banded_cost ? (
                    <p>{props.app.band_tag}</p>
                ) : (
                    <p>£{props.app?.price} per {props.app?.scale_tag}</p>        
                )
            }
            
            {
              props.app.bands && Array.isArray(props.app.bands) ?  props.app.bands.map((value) => {
                    <p>{value}</p>
                }) : null
            }
            <Grid container spacing={2} justifyContent="space-between" alignItems="center" className="app-controls">
                {
                    props.app.core ? (
                        <Grid item xs={6}>
                            <Button
                                disabled
                                variant="contained"
                            >
                                Required
                            </Button>
                        </Grid>
                    ) : (
                    added ? (
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => { removeApp() }}
                            >
                                <CloseIcon/>
                            </Button>
                        </Grid>
                        ) : (
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => { addApp() }}
                                className="add-btn"
                            >
                                Add
                            </Button>
                        </Grid>
                        )
                    )
                }
                {
                    added && !props.app.core && props.app.banded_cost && (
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="band-selector-label">{props.app?.band_tag}</InputLabel>
                                <NativeSelect
                                    size="small"
                                    value={band}
                                    onChange={(e) => {handleBandChange(e.target.value)}}
                                    fullWidth
                                    label={props.app?.band_tag}
                                    inputProps={{
                                        name: props.app?.band_tag,
                                        id: "uncontrolled-native"
                                    }}
                                >
                                    {
                                    props.app.bands && Array.isArray(props.app.bands) ?    props.app.bands.map((value, key) => {
                                            return (
                                                <option key={key} value={value}>{value} - {props.app.band_prices[key] !== undefined ? `£${props.app.band_prices[key].toFixed(2)}` : `Contact Us For Details`}</option>
                                            )
                                        }) : null
                                    }
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                    )
                }
                {
                    added && !props.app.core && !props.app.banded_cost && (
                        <Grid item xs={4}>
                            <Input
                                size="small"
                                value={users}
                                onChange={(e) => {handleUserChange(e.target.value)}}
                                type="number"
                                endAdornment={<InputAdornment position="end">{props.app?.scale_tag}s</InputAdornment>}
                            />
                        </Grid>
                    )
                }
            </Grid>
            {
                added && price !== "0.00" && (
                    <p className="subTotal">{price}</p>
                )
            }
        </Paper>
    )
}